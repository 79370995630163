import React from "react"
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer"
import innovis_adobe_express from "./../../../../Assets/Img/innovis_adobe_express.png"

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		alignItems: "center",
	},
	header: {
		flexDirection: "row",
		justifyContent: "space-between",
		marginBottom: 10,
	},
	membrete: {
		paddingLeft: 15,
	},
	logo: {
		width: 140,
		height: 90,
		margin: 20,
	},
	title: {
		fontSize: 38,
		fontWeight: "bold",
	},
	highlight: {
		color: "#fff",
		fontSize: 13,
		backgroundColor: "rgb(47, 161, 161)",
		padding: 1,
	},
	subtitle: {
		fontSize: 15,
		fontWeight: "bold",
	},
	campo: {
		fontSize: 15,
		fontWeight: "bold",
	},
	cardInfo: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	info: {
		fontSize: 12,
		fontWeight: "light",
		textAlign: "left",
		paddingTop: 1,
	},
	date: {
		textAlign: "center",
		fontSize: 12,
		paddingLeft: 35,
	},
	spacer: {
		width: 40,
	},
	table: {
		display: "table",
		width: "90%",
		borderStyle: "solid",
		borderWidth: 1,
		borderRightWidth: 0,
		borderBottomWidth: 0,
	},
	tableRow: {
		// margin: "auto",
		flexDirection: "row",
		//width: "90%"
	},
	tableRowTitle: {
		flexDirection: "row",
	},
	tableCol: {
		width: "30%",
		borderStyle: "solid",
		borderWidth: 1,
		borderLeftWidth: 0,
		borderTopWidth: 0,
	},
	tableColFirst: {
		width: "30%",
		borderStyle: "solid",
		borderWidth: 1,
		borderTopWidth: 0,
		borderLeftWidth: 0,
	},
	tableCell: {
		margin: "auto",
		marginTop: 4,
		marginBottom: 4,
		fontSize: 8,
		padding: 1,
	},
	tableTitle: {
		margin: "auto",
		marginTop: 4,
		marginBottom: 4,
		fontSize: 8,
		color: "rgb(47, 161, 161)",
		padding: 1,
	},
})

const StockoutPDF = ({ productsToOrder, isOtherProducts }) => {
	const titulo = "CONTROL DE STOCK"

	const PRODUCTS_TITLES = [
		"Código",
		"Nombre",
		"Proveedor",
		"Marca",
		"Unidad",
		"Nbu",
		"Código de Lote",
		"Cantidad de Lotes",
		"Ingreso",
		"Vencimiento",
		"Cantidad",
		"Estado",
	]

	const OTHER_PRODUCTS_TITLES = [
		"Código",
		"Nombre",
		"Proveedor",
		"Categoría",
		"Cantidad",
		"Estado",
	]

	return (
		<Document>
			<Page style={styles.body}>
				<View style={styles.header}>
					<View style={styles.membrete}>
						<Text style={styles.title}>{titulo}</Text>
						<Text style={styles.subtitule}>INNOVIS LABORATORIOS BIOQUÍMICOS</Text>
						<View style={styles.spacer} />
					</View>
					<View style={styles.membrete}>
						<Image
							src={innovis_adobe_express}
							style={styles.logo}
						/>
						<Text style={styles.info}>Laboratorio bioquímico integral</Text>
						<Text style={styles.date}>Fecha: {new Date().toLocaleDateString()}</Text>
					</View>
				</View>
				<View style={styles.table}>
					<View style={styles.tableRowTitle}>
						{(isOtherProducts ? OTHER_PRODUCTS_TITLES : PRODUCTS_TITLES).map(title => {
							return (
								<View style={styles.tableCol} key={title}>
									<Text style={styles.tableTitle}>{title}</Text>
								</View>
							)
						})}
					</View>
					{!isOtherProducts && productsToOrder.map(({ product, lot }) => {
						return (
							<View
								style={styles.tableRow}
								key={lot?._id}
							>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.code}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.name}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.provider.name}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.brand}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.unitType?.name || product?.measure?.name}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.ubn}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{lot?.lotNumber}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{lot?.lotQuantity}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{lot?.acquireDate}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{lot?.expireDate}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{lot?.quantity}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{lot?.state}</Text>
								</View>
							</View>
						)
					})}
					{isOtherProducts && productsToOrder.map(({ product }) => {
						return (
							<View
								style={styles.tableRow}
								key={product?._id}
							>
								<View style={styles.tableColFirst}>
									<Text style={styles.tableCell}>{product?.code || "-"}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.name}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.provider.name}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.category.name || "-"}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.quantity}</Text>
								</View>
								<View style={styles.tableCol}>
									<Text style={styles.tableCell}>{product?.state}</Text>
								</View>
							</View>
						)
					})}
				</View>
			</Page>
		</Document>
	)
}

export default StockoutPDF
