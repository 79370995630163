import React, { useState } from 'react';
import Sidebar from '../Components/SideBar/SideBar.jsx';
import { Tooltip } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Cookies from 'js-cookie';
import './utilities.css';




function AppLayout({ children }) {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const role = Cookies.get('role');

    const handleDrawerToggle = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const drawerMargin = 16;
    const drawerTop = 2;

    return (
        <div className={`app-layout ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
            {/* Botón de toggle para abrir/cerrar el sidebar */}
            <Tooltip title={isSidebarOpen ? "Cerrar menú" : "Abrir menú"} arrow>
                <button className='hamburguer'
                    onClick={handleDrawerToggle}
                    style={{
                        // marginTop: drawerTop,
                        zIndex: '1300',
                        position: 'fixed',
                        marginLeft: isSidebarOpen ? drawerMargin : 0,
                        transition: 'margin-left 0.3s'
                    }}
                >
                    <MenuIcon />
                </button>
            </Tooltip>
            {/* Sidebar */}
            <Sidebar isOpen={isSidebarOpen} />
            {/* Contenido principal */}
            <main className={`main-content ${isSidebarOpen ? 'expanded' : 'centered'}`}>
                {children}
            </main>
        </div>
    );
}

export default AppLayout;

