import React, { Fragment } from "react"
import { Form, Table } from "react-bootstrap"

export default function ProductsTable({ orderItems, originalTags = [], setOrderItems, itemsStartIndex, itemsEndIndex, disabled, handleItems, role, isProductTable, orderState }) {
	return (
		<Table responsive
			className="mb-3  rounded"
			borderless
		>
			<thead>
				<tr>
					<th className="text-start">Nombre</th>
					{!orderState && <th>C/ Pedida</th>}
					{role === "sede" && orderState && <th>C/ a Recibir</th>}
					{role !== "sede" && (
						<>
							<th>C/ a Mandar (ud/s.)</th>
							<th className="quantity-available">Disponibles</th>
						</>
					)}
					<th>Medida</th>
					<th>Administrar</th>
				</tr>
			</thead>

            <tbody>
    {/* Mostrar etiquetas solo para el rol sede  */}
    {role === "sede" && orderState !== "Autopedido" && Array.isArray(originalTags) && originalTags.length > 0 ? (
    originalTags.map(tag => (
        <tr key={tag.name || tag.data._id}>
            <td className="text-start boldBigText">{tag.name || tag.data.name}</td>
            <td>{orderState ? tag.quantityRequested || "-" : tag.quantityRequested}</td> 
            <td>{tag.products?.[0]?.measure || tag.data.unitType?.name || "Unidad"}</td>
            <td>
                    <span>Eliminar</span>
                    <Form.Check
                        className="d-inline p-1"
                        type="checkbox"
                        checked={false}
                        disabled={disabled}
                    />
                </td>
            </tr>
        ))
    ) : (
        /* Mostrar productos individuales para autopedidos */
        orderItems.slice(itemsStartIndex, itemsEndIndex).map(item => {
            const isAutoPedido = orderState === "Autopedido"; // Verificar si es un autopedido
            const tagProducts = item.isTag
                ? (item.data.products.length
                    ? item.data.products
                    : item.data.otherProducts)
                : [];

            return (
                <Fragment key={item._id}>
                    <tr className={`${!tagProducts.length && "border-bottom-table"} `}>
                        <td className="text-start boldBigText">{item.data.name}</td>
                        {role === "sede" && !isAutoPedido ? (
                            <>
                                <td className="quantity-td">
                                    {orderState ? (
                                        item.quantityToSend ? item.quantityToSend : "0"
                                    ) : (
                                        <input
                                            style={{ width: "100px", display: "inline" }}
                                            type="number"
                                            min="1"
                                            value={item.quantityRequested || ""}
                                            onChange={e =>
                                                setOrderItems(prev =>
                                                    prev.map(p => {
                                                        if (p.data._id === item.data._id) {
                                                            return {
                                                                ...p,
                                                                quantityRequested: parseInt(e.target.value),
                                                            };
                                                        }
                                                        return p;
                                                    })
                                                )
                                            }
                                            disabled={disabled}
                                        />
                                    )}
                                </td>
                                <td>
                                    {item.data.measure?.name || item.data.unitType?.name}
                                </td>
                                <td>
                                    <span>Eliminar</span>
                                    <Form.Check
                                        className="d-inline p-1"
                                        type="checkbox"
                                        checked={false}
                                        onChange={() =>
                                            setOrderItems(prev =>
                                                prev.filter(p => p.data._id !== item.data._id)
                                            )
                                        }
                                        disabled={disabled}
                                    />
                                </td>
                            </>
                        ) : (
                            <>
                                {!orderState && <td>{`${item.quantityRequested || ""}`}</td>}
                                {!tagProducts.length || isAutoPedido ? (
                                    <>
                                        <td className="quantity-td">
                                            <input
                                                style={{ width: "100px", display: "inline" }}
                                                type="number"
                                                min="1"
                                                value={item.quantityToSend || ""}
                                                onChange={e =>
                                                    setOrderItems(prev =>
                                                        prev.map(p => {
                                                            if (p.data._id === item.data._id) {
                                                                return {
                                                                    ...p,
                                                                    quantityToSend: e.target.value === ""
                                                                        ? ""
                                                                        : parseInt(e.target.value),
                                                                };
                                                            }
                                                            return p;
                                                        })
                                                    )
                                                }
                                                disabled={
                                                    orderState !== undefined &&
                                                    orderState !== "Aceptado" &&
                                                    orderState !== "Aceptado sin Stock"
                                                }
                                            />
                                        </td>
                                        <td
                                            className={
                                                item.data.quantity - item.quantityToSend < 0
                                                    ? "text-red"
                                                    : ""
                                            }
                                        >
                                            {`${item.data.quantity} ud/s.`}
                                        </td>
                                    </>
                                ) : (
                                    <>
                                        <td>-</td>
                                        <td>-</td>
                                    </>
                                )}

                                <td>
                                    {`${item.data.measure?.name || item.data.unitType?.name} ${
                                        item.data.weightOrVolume
                                            ? "(" + item.data.weightOrVolume + " c/u)"
                                            : ""
                                    }`}
                                </td>

                                <td>
                                    <span>Pendiente</span>
                                    <Form.Check
                                        className="d-inline p-1"
                                        type="checkbox"
                                        checked={false}
                                        onChange={() => handleItems(item.data._id)}
                                        disabled={disabled}
                                    />
                                </td>
                            </>
                        )}
                    </tr>
                    {role !== "sede" &&
                        tagProducts &&
                        tagProducts.map((product, i) => {
                            const currentOrderSubitem = orderItems
                                .find(item => {
                                    if (typeof item.quantityToSend == "object") {
                                        return item.quantityToSend?.some(
                                            subitem => subitem._id === product._id
                                        );
                                    } else {
                                        return false;
                                    }
                                })
                                ?.quantityToSend.find(
                                    subitem => subitem._id === product._id
                                );

                            return (
                                <tr
                                    key={product._id}
                                    className={
                                        i + 1 === tagProducts.length && "border-bottom-table"
                                    }
                                >
                                    <td className="text-start">{product.name}</td>
                                    {!orderState && <td>-</td>}
                                    <td className="quantity-td">
                                        <input
                                            style={{ width: "100px", display: "inline" }}
                                            type="number"
                                            min="1"
                                            value={
                                                item.quantityToSend?.find(
                                                    subItem => subItem._id === product._id
                                                )?.quantityToSend || ""
                                            }
                                            onChange={e =>
                                                setOrderItems(prev =>
                                                    prev.map(orderItem => {
                                                        if (orderItem.data._id === item.data._id) {
                                                            let quantityToSendArr =
                                                                orderItem.quantityToSend || [];

                                                            if (
                                                                quantityToSendArr.some(
                                                                    subItem =>
                                                                        subItem._id === product._id
                                                                )
                                                            ) {
                                                                quantityToSendArr =
                                                                    quantityToSendArr.map(subItem => {
                                                                        if (subItem._id === product._id) {
                                                                            return {
                                                                                ...subItem,
                                                                                quantityToSend:
                                                                                    e.target.value === ""
                                                                                        ? ""
                                                                                        : parseInt(e.target.value),
                                                                            };
                                                                        } else {
                                                                            return subItem;
                                                                        }
                                                                    });
                                                            } else {
                                                                quantityToSendArr.push({
                                                                    _id: product._id,
                                                                    quantityToSend:
                                                                        e.target.value === ""
                                                                            ? ""
                                                                            : parseInt(e.target.value),
                                                                    currentQuantity: product.quantity,
                                                                    measure: product.unitType.name,
                                                                    weightOrVolume: product?.weightOrVolume,
                                                                });
                                                            }

                                                            return {
                                                                ...orderItem,
                                                                quantityToSend: quantityToSendArr,
                                                            };
                                                        } else {
                                                            return orderItem;
                                                        }
                                                    })
                                                )
                                            }
                                            disabled={disabled}
                                        />
                                    </td>
                                    <td
                                        className={
                                            currentOrderSubitem?.currentQuantity -
                                            currentOrderSubitem?.quantityToSend <
                                            0
                                                ? "text-red"
                                                : ""
                                        }
                                    >
                                        {product.quantity} ud/s.
                                    </td>
                                    <td>
                                        {`${product.unitType.name} ${
                                            product.weightOrVolume
                                                ? "(" + product.weightOrVolume + " c/u)"
                                                : ""
                                        }`}
                                    </td>
                                    <td>-</td>
                                </tr>
                            );
                        })}
                </Fragment>
            );
        })
    )}
</tbody>

		</Table>
	)
}
