import React, { useEffect, useState } from "react"
import editIcon from "../../../../Assets/Img/iconEdit.svg"
import DatePicker from "react-datepicker"
import axios from "axios"
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI

export default function LotRow({ lot, isEven, reload }) {
	const [lotNumber, setLotNumber] = useState(lot.lotNumber)
	const [acquireDate, setAcquireDate] = useState(lot.acquireDate)
	const [expireDate, setExpireDate] = useState(lot.expireDate)
	const [quantity, setQuantity] = useState(lot.quantity)
	const [lotQuantity, setLotQuantity] = useState(lot.lotQuantity)

	const [isEditing, setIsEditing] = useState(false)

	const handleIsEditing = () => {
		setIsEditing(prev => !prev)
	}

	const submitLotEdition = async () => {
		await axios({
			method: "put",
			url: `${BACK_APP_URI}/lotes/edit/${lot._id}`,
			data: {
				lotNumber: lotNumber,
				expireDate: expireDate,
				acquireDate: acquireDate,
				quantity: quantity,
				product: lot.product,
				lotQuantity: lotQuantity,
			},
		})
		setIsEditing(false)
		reload("Lote editado correctamente")
	}

	const backgroundColor = isEven ? "backgound-veryLight" : ""

	const Td = ({ children }) => {
		return (
			<td
				className={`${backgroundColor}`}
				style={{
					padding: "8px"
				}}
			>
				{children}
			</td>
		)
	}

	if (isEditing) {
		return (
			<tr>
				<Td>
					<input
						name="lotNumber"
						className="input-LotRow no-arrows"
						onChange={e => setLotNumber(e.target.value)}
						value={lotNumber}
						type="text"
					/>
				</Td>
				<Td className="relative">
					<DatePicker
						className="input-LotRow"
						placeholderText="Seleccionar"
						name="acquireDate"
						dateFormat="dd/MM/yyyy"
						input
						type="date"
						selected={acquireDate}
						onChange={date => setAcquireDate(date)}
						autoComplete="off"
					/>
				</Td>
				<Td className="relative">
					<DatePicker
						placeholderText="Seleccionar"
						className="input-LotRow"
						name="acquireDate"
						dateFormat="dd/MM/yyyy"
						input
						type="date"
						selected={new Date(expireDate)}
						onChange={date => setExpireDate(date)}
						autoComplete="off"
					/>
				</Td>
				<Td>
					<input
						name="quantity"
						className="input-LotRow no-arrows"
						onChange={e => setQuantity(e.target.value)}
						value={quantity}
						type="number"
					/>
				</Td>
				<Td>
					<input
						name="lotQuantity"
						className="input-LotRow no-arrows"
						onChange={e => setLotQuantity(e.target.value)}
						value={lotQuantity}
						type="number"
					/>
				</Td>
				<Td>
					<button
						className="lotButton"
						onClick={submitLotEdition}
					>
						<span className="color-green">✔</span>
					</button>
				</Td>
			</tr>
		)
	} else {
		return (
			<tr>
				<Td>{lotNumber}</Td>
				<Td>{`${new Date(acquireDate).getDate()}/${new Date(acquireDate).getMonth() + 1}/${new Date(acquireDate).getFullYear()}`}</Td>
				<Td>{`${new Date(expireDate).getDate()}/${new Date(expireDate).getMonth() + 1}/${new Date(expireDate).getFullYear()}`}</Td>
				<Td>{quantity}</Td>
				<Td>{lotQuantity}</Td>
				<Td>
					{" "}
					<button
						className="lotButton"
						onClick={handleIsEditing}
					>
						<img
							src={editIcon}
							alt="editar lote"
						/>
					</button>
				</Td>
			</tr>
		)
	}
}
