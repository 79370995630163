import React, { useEffect, useState } from "react"
import { Spinner } from "react-bootstrap"
import "./spinner.css"
import { Transition } from "react-transition-group"

export default function SpinnerComponent({ children, isLoading, alternative, isAlternative }) {
	return (
		<>
			{isLoading && (
			
				<div
					className="spinner"
					style={{ display: "flex", justifyContent: "center" }}
				>
					<Spinner
						animation="border"
						role="status"
					>
						<span className="visually-hidden">Cargando...</span>
					</Spinner>
				</div>
				
			)}

			<Transition
				in={!isLoading}
				timeout={0}
			>
				{state => (
					<div
						style={{
							transition: `transform ${state === "exiting" ? "0s" : "0.6s"} ease-in-out, opacity ${state === "exiting" ? "0s" : "1s"} ease-in-out`,
							opacity: state === "entered" ? 1 : 0,
							transform: state === "entered" ? "translateY(0%)" : "translateY(20%)",
						}}
					>
						{isAlternative ? alternative : children}
					</div>
				)}
			</Transition>
		</>
	)
}
