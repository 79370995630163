import React, { useEffect, useState } from "react";
import "./AllOrders.css";
import delyIcon from "./../../../Assets/Img/delivery-truck-4439.svg"
import { Link } from "react-router-dom";
import { Button, Card, Form, Pagination } from "react-bootstrap";
import Cookies from "js-cookie";
import axios from "axios";
import AbmButtons from "../../ABMbotones/abmButtons";
import { DateRangePicker } from "react-dates";
import moment from 'moment';
import iconCheck from "./../../../Assets/Img/check-circle-svgrepo-com.svg"
import fileExport from "../../../Assets/Img/file-export-white.svg"
import ExportOrderModal from "../ExportOrderModal";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Popover, Box, Typography, Tooltip, Chip } from '@mui/material';




const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;




export default function AllOrders() {


  const [isAdmin, setIsAdmin] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [filterUserLabType, setFilterUserLabType] = useState("");
  const [id, setId] = useState(null);
  const [AllLabs, setAllLabs] = useState([]);
  const [filteredLab, setFilteredLab] = useState("");
  const [filteredState, setFilteredState] = useState("");
  const [allProviders, setAllProviders] = useState([]);
  const [orderSelected, setOrderSelected] = useState(null);
  const [orderToPdfModal, setOrderToPdfModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const ordersPerPage = 10;
  const [allProducts, setAllProducts] = useState([]);
  const [allOrderStates, setAllOrderStates] = useState([]);
  const [labsAssigned, setLabsAssigned] = useState([]);



  const pagesVisited = pageNumber * ordersPerPage;
  const pageCount = Math.ceil((allOrders.length - 1) / ordersPerPage);


  const changePage = (selected) => {
    setPageNumber(selected);
  };


  //---------------Date Range Picker------------------------------------------------


  moment.locale('es'); // Establece el idioma para Moment.js
  const [startDate, setStartDate] = useState(moment().subtract(7, 'days'));
  const [endDate, setEndDate] = useState(moment());
  const [focusedInput, setFocusedInput] = useState(null);
  const [pageCountForButton, setPageCountForButton] = useState(0);


  const startDateTime = new Date(startDate).getTime();
  const endDateTime = new Date(endDate).getTime();



  useEffect(() => {
    const filteredOrdersCount = allOrders.filter(order =>
      (new Date(order.createdAt).getTime() >= startDateTime && new Date(order.createdAt).getTime() <= endDateTime)
    ).length;
    const calculatedPageCount = Math.ceil(filteredOrdersCount / ordersPerPage);
    setPageCountForButton(calculatedPageCount)
  }, [startDateTime, endDateTime, allOrders])


  //Refresh
  function refreshPage() {
    window.location.reload(false);
  }


  // Registro de campos y validación
  const selected = (order) => {
    setOrderSelected(order._id);
  };
  const deselected = () => {
    setOrderSelected(null);
  };


  // Obtengo todos los pedidos
  useEffect(() => {
    async function getAllOrders() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/pedidos`, {
          headers: {
            Authorization: `${token}`,
          },
        })


        .then(async (result) => {
          setAllOrders(result.data.orders);
          setFilterUserLabType(result.data.username)
          setIsAdmin(result.data.userdata)
          setAllOrderStates(removeDuplicates(result.data.orders.map(order => order.state || "Pendiente")))


          const filteredByDate = result.data.orders.filter((order) => (new Date(order.createdAt).getTime() >= startDateTime) && (new Date(order.createdAt).getTime() <= endDateTime));


          let filteredOrders = filteredByDate


          if (filteredLab) {
            filteredOrders = filteredOrders.filter(order => order.labOrigin._id === filteredLab)


          }


          if (filteredState) {
            filteredOrders = filteredOrders.filter(order => {


              if (!order.hasOwnProperty("state") && filteredState === "Pendiente") return true


              return order.state === filteredState
            })
          }


          setAllOrders(filteredOrders)



          if (isAdmin !== "admin") {


            const response = await axios.get(`${BACK_APP_URI}/users/findByUsername/${result.data.username}`, {
              headers: {
                Authorization: `${token}`,

              },
            });

            setLabsAssigned(response.data.labs)
          }
        });
    }
    getAllOrders();
  }, [filteredLab, startDateTime, endDateTime, isAdmin, filteredState]);


  // Obtengo Laboratorios para filtrar
  useEffect(() => {
    async function getLabs() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/laboratory`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          setAllLabs(res.data.laboratories);
          setIsAdmin(res.data.userdata);
        });
    }
    async function getProviders() {
      const token = Cookies.get("token");
      await axios
        .get(`${BACK_APP_URI}/proveedor`, {
          headers: {
            Authorization: `${token}`,
          },
        })
        .then((res) => {
          //console.log("Providers", res.data.provider)
          setAllProviders(res.data.provider);
        });
    }
    getLabs();
    getProviders();
  }, []);


  const select = (order) => {
    setId(order._id);
  };


  //Borrar pedido
  const deleteOrder = (id) => {
    axios({
      method: "delete",
      url: `${BACK_APP_URI}/pedidos/delete/${orderSelected}`,
    }).then(refreshPage());
  };


  //--------------------Handlers--------------------------
  const handleDateRangeChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    /*     console.log("Start Date:", startDate);
        console.log("End Date:", endDate); */
  }


  const isOutsideRange = (day) => {
    return false; // Habilita todos los días
  };


  function removeDuplicates(array) {
    return [...new Set(array)];
  }


  const handleClearFilteredData = () => {
    setFilteredState("")
    setFilteredLab("")
    setStartDate(moment().subtract(7, 'days'))
    setEndDate(moment())
    changePage(0)
  }

  /*Estado para popover de informacion de pedido */
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleOpen = (event, order) => {
    setAnchorEl(event.currentTarget);
    setSelectedOrder(order);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedOrder(null);
  };

  const isOpen = Boolean(anchorEl);

  // const style = {
  //   position: 'absolute',
  //   top: '50%',
  //   left: '50%',
  //   transform: 'translate(-50%, -50%)',
  //   width: 600,
  //   height: 600,

  //   p: 4,
  // };

  /*Estados para los colores de chip e indicador*/
  const getStatusColor = (state, hasPendingProducts, isAcceptedWithoutStock, role) => {

    if (role === "sede" && isAcceptedWithoutStock) {
      return "badge-danger-light";
    }
    if (hasPendingProducts && isAcceptedWithoutStock) {
      return "badge-orange";
    }
    if (hasPendingProducts) {
      return "badge-warning";
    }
    if (isAcceptedWithoutStock) {
      return "badge-danger-light";
    }
    switch (state) {
      case "Aceptado":
        return "badge-success";
      case "Rechazado":
        return "badge-danger";
      case "Enviado":
        return "badge-info";
      case "Recibido":
        return "badge-primary";
      default:
        return "badge-secondary";
    }
  };



  const getIndicatorColor = (state, hasPendingProducts, isAcceptedWithoutStock, role) => {

    if (role === "sede" && isAcceptedWithoutStock) {
      return "indicator-danger-light";
    }
    if (hasPendingProducts && isAcceptedWithoutStock) {
      return "indicator-orange";
    }
    if (hasPendingProducts) {
      return "indicator-warning";
    }
    if (isAcceptedWithoutStock) {
      return "indicator-danger-light";
    }
    switch (state) {
      case "Aceptado":
        return "indicator-success";
      case "Rechazado":
        return "indicator-danger";
      case "Enviado":
        return "indicator-info";
      case "Recibido":
        return "indicator-primary";
      default:
        return "indicator-secondary";
    }
  };




  return (



    <div className="main-container" >
      <div className="header--container">
        <h1 className="title--header">Pedidos</h1>


        {isAdmin !== "sede"
          ?
          <div className="select-container-order"
            onClick={deselected}>
            <Form.Select
              value={filteredState}
              className="form-order"
              onChange={(e) => {
                setFilteredState(e.target.value);
                changePage(0);
              }}>
              <option value="" disabled>Seleccionar Estado</option>
              <option value="">Todos los Estados</option>
              {allOrderStates.map(state => {
                return <option
                  key={state}
                  value={state}
                >
                  {state}
                </option>
              })}
            </Form.Select>


            <Form.Select
              value={filteredLab}
              className="form-order"
              onChange={(e) => {
                setFilteredLab(e.target.value);
                changePage(0);
              }}>
              <option value="" disabled>Seleccionar Laboratorio</option>
              <option value="">Todos los Laboratorios</option>
              {isAdmin === "logistic" ? (labsAssigned.map((lab, i) => {
                return <option
                  key={lab._id}
                  value={lab._id}
                >
                  {lab.name}
                </option>
              })) : (
                AllLabs.map((lab, i) => {
                  return <option
                    key={lab._id}
                    value={lab._id}
                  >
                    {lab.name}
                  </option>
                })
              )}
            </Form.Select>
            {/* Date picker */}
              <DateRangePicker
                startDate={startDate}
                startDateId="your_unique_start_date_id"
                endDate={endDate}
                endDateId="your_unique_end_date_id"
                onDatesChange={handleDateRangeChange}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                small
                displayFormat="DD/MM/YY"
                monthFormat="MMMM YYYY"
                weekDayFormat="dd"
                isOutsideRange={isOutsideRange}
              />


              <Button className="btn-order" onClick={() => setOrderToPdfModal(true)}>
                <img src={fileExport} alt="" className="icon-order-btn" />
                Exportar
              </Button>

          </div>

          :
          <div className=" mb-3 d-flex"
            style={{ gap: '3rem', width: '100%', justifyContent: 'space-between' }}
            onClick={deselected}>
            <DateRangePicker
              startDate={startDate}
              startDateId="your_unique_start_date_id"
              endDate={endDate}
              endDateId="your_unique_end_date_id"
              onDatesChange={handleDateRangeChange}
              focusedInput={focusedInput}
              onFocusChange={focusedInput => setFocusedInput(focusedInput)}
              small
              displayFormat="DD/MM/YY" // Define el formato de visualización
              monthFormat="MMMM YYYY"
              weekDayFormat="dd"
              isOutsideRange={isOutsideRange}
            />
            <Form.Select
              value={filteredState}
              className=" w-25"
              onChange={(e) => {
                setFilteredState(e.target.value);
                changePage(0);
              }}>
              <option value="" disabled>Seleccionar Estado</option>
              <option value="">Todos los Estados</option>
              {allOrderStates.map(state => {
                return <option
                  key={state}
                  value={state}
                >
                  {state}
                </option>
              })}
            </Form.Select>

          </div>

        }
      </div>
      <div className="card--container">
        <div className="card-new-order">

          <div className="title-card-order hidden-menu">
            <div className="title-content-grid">
              <div className="header-cell">Pedido</div>
              <div className="header-cell">Sede</div>
              <div className="header-cell">Productos</div>
              <div className="header-cell">Fecha</div>
              <div className="header-cell">Estado</div>
              <div className="header-cell">Observación</div>
            </div>
          </div>



          {/* Si es usuario sede */}


          {isAdmin === "sede" ? (
            <>
              {allOrders
                .filter((order) => {
                  return labsAssigned?.some((lab) => lab._id === order.labOrigin._id);
                })
                .filter((order) => {
                  return (
                    new Date(order.createdAt).getTime() >= startDateTime &&
                    new Date(order.createdAt).getTime() <= endDateTime
                  );
                })
                .slice(pagesVisited, pagesVisited + ordersPerPage)
                .map((order) => {
                  const statusColor = getStatusColor(order.state);
                  const indicatorColor = getIndicatorColor(order.state);

                  return (
                    <div className="card-container" key={order._id}>
                      <Card

                        className={`card-order ${order._id === orderSelected ? "selected-order" : ""
                          }`}
                      >
                        <Card.Body
                          className="item-content-order"
                          style={{ padding: "0px" }}
                        >
                          <div
                            className={`order-indicator ${getIndicatorColor(
                              order.state,
                              order.pendingProducts.length > 0 ||
                              order.pendingOtherProducts.length > 0 ||
                              order.pendingTags.length > 0,
                              order.state === "Aceptado sin Stock"
                            )}`}
                          ></div>

                          {/* Contenido de la card */}
                          <div className="card-content-order-grid">
                            <div className="card-content-row">
                              <div className="order-cell">
                                <strong>Nº:</strong> {order.codigoPedido}
                              </div>
                              <div className="order-cell">
                                {order.labOrigin?.name || "Nombre no disponible"}
                              </div>
                              <div className="order-cell">
                                {order.isFromBiochemicalRole ? (
                                  <strong className="autopedido-text">Autopedido</strong>

                                ) : order.state === "Aceptado sin Stock" ? (
                                  <span>Sin stock</span>
                                ) : order.products.length > 0 ||
                                  order.tags.filter((tag) => tag.data.isProducts).length > 0 ? (
                                  // Mostrar la cantidad de productos bioquímicos
                                  <p>
                                    Productos bioquímicos: {order.products.length + order.tags.filter((tag) => tag.data.isProducts).length}
                                  </p>
                                ) : order.otherProducts.length > 0 ||
                                  order.tags.filter((tag) => !tag.data.isProducts).length > 0 ? (
                                  // Mostrar la cantidad de productos varios
                                  <p>
                                    Productos Varios: {order.otherProducts.length + order.tags.filter((tag) => !tag.data.isProducts).length}
                                  </p>
                                ) : (
                                  // Si no hay productos
                                  "Sin productos"
                                )}
                              </div>

                              <div className="order-cell">
                                {new Date(order.createdAt).toLocaleDateString("es-ar")}
                              </div>
                              <div className="order-cell-row">
                                <div className="order-cell">
                                  <div
                                    style={{
                                      minHeight: "24px",
                                      minWidth: "100px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {order.state && (
                                      <Chip
                                        style={{ padding: "6px", fontSize: "11px" }}
                                        className={`chip-badge ${getStatusColor(
                                          order.state,
                                          order.pendingProducts.length > 0 ||
                                          order.pendingOtherProducts.length > 0,
                                          order.state === "Aceptado sin Stock"
                                        )}`}
                                        icon={
                                          order.state === "Enviado" ? (
                                            <LocalShippingOutlinedIcon
                                              style={{ width: "16px", color: "#0c8597" }}
                                            />
                                          ) : order.state === "Recibido" ? (
                                            <TaskAltOutlinedIcon
                                              style={{ width: "16px", color: "#388e3c" }}
                                            />
                                          ) : null
                                        }
                                        label={order.state}
                                      />
                                    )}
                                  </div>
                                </div>
                                <div className="order-cell">
                                  <Tooltip title="Ver información">
                                    <Button
                                      style={{
                                        backgroundColor: "transparent",
                                        borderRadius: "5px",
                                      }}
                                      onClick={(event) => handleOpen(event, order)}
                                    >
                                      <InfoOutlinedIcon
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          color: "#056a6c",
                                        }}
                                      />
                                    </Button>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="order-cell order-cell-observacion" style={{ fontSize: "14px",  width: '220px'  }}>
                                {order.state === "Aceptado sin Stock" ? (
                                  <span>Sin stock para completar</span>
                                ) : order.pendingProducts.length > 0 || order.pendingOtherProducts.length > 0 ? (

                                  <span>Aceptado</span>
                                ) : order.comment ? (
                                  // Limitar a 40 caracteres si es necesario
                                  <span>{order.comment.length > 40 ? `${order.comment.slice(0, 40)}...` : order.comment}</span>
                                ) : null}
                              </div>
                            </div>
                            <div className="card-content-row">
                              <div className="order-action-container">
                                <Tooltip title="Ver pedido">
                                  <Link
                                    to={`/orders/edit/${order._id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <Button
                                      className="btn-see-order"
                                      onClick={() => selected(order)}
                                      style={{ marginRight: 0 }}
                                    >
                                      <NavigateNextRoundedIcon className="icon-btn-see-order" />
                                    </Button>
                                  </Link>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  );
                })}
            </>
          ) : (
            <>


              {/* Si es usuario administrador o logistica */}

              <div className="order-list">
                {allOrders.length > 0 ? (
                  allOrders
                    .filter((order) => {
                      if (isAdmin !== "logistic") return true;
                      return labsAssigned?.some((lab) => lab._id === order.labOrigin._id);
                    })
                    .filter((order) => {
                      return (
                        new Date(order.createdAt).getTime() >= startDateTime &&
                        new Date(order.createdAt).getTime() <= endDateTime
                      );
                    })
                    .slice(pagesVisited, pagesVisited + ordersPerPage)
                    .map((order) => {
                      const statusColor = getStatusColor(order.state);
                      const indicatorColor = getIndicatorColor(order.state);

                      return (
                        <div className="card-container" key={order._id}>
                          <Card
                            onClick={() => selected(order)}
                            className={`card-order ${order._id === orderSelected ? "selected-order" : ""}`}
                          >

                            <Card.Body className="item-content-order" style={{ padding: '0px' }}>
                              <div className={`order-indicator ${getIndicatorColor(order.state, order.pendingProducts.length > 0 || order.pendingOtherProducts.length > 0, order.state === "Aceptado sin Stock")}`}></div>

                              {/* Contenido de la card */}

                              <div className="card-content-order-grid">
                                <div className="card-content-row">
                                  <div className="order-cell">
                                    <strong>Nº:</strong>  {order.codigoPedido}
                                  </div>
                                  <div className="order-cell">
                                    {order.labOrigin?.name || "Nombre no disponible"}
                                  </div>
                                  <div className="order-cell">
                                    {(order.state === "Aceptado sin Stock") ? (
                                      <span>Sin stock</span>
                                    ) : (order.pendingProducts.length > 0 || order.pendingOtherProducts.length > 0) ? (
                                      <span>Pendientes</span>
                                    ) : (order.products.length > 0 || order.tags.filter(tag => tag.data.isProducts).length > 0) ? (
                                      <p>Productos bioquímicos: {order.products.length + order.tags.length}</p>
                                    ) : (
                                      (order.otherProducts.length > 0 || order.tags.filter(tag => !tag.data.isProducts).length > 0) ? (
                                        <p>Productos Varios: {order.otherProducts.length + order.tags.length}</p>
                                      ) : "Sin productos"
                                    )}
                                  </div>
                                  <div className="order-cell">
                                    {new Date(order.createdAt).toLocaleDateString("es-ar")}
                                  </div>
                                  <div className="order-cell-row">
                                    <div className="order-cell">

                                      <div style={{ minHeight: '24px', minWidth: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        {order.state && (
                                          <Chip
                                            style={{ padding: '6px', fontSize: '11px' }}
                                            className={`chip-badge ${getStatusColor(order.state, order.pendingProducts.length > 0 || order.pendingOtherProducts.length > 0, order.state === "Aceptado sin Stock")}`}
                                            icon={
                                              order.state === 'Enviado' ? (
                                                <LocalShippingOutlinedIcon style={{ width: '16px', color: '#0c8597' }} />
                                              ) : order.state === 'Recibido' ? (
                                                <TaskAltOutlinedIcon style={{ width: '16px', color: '#388e3c' }} />
                                              ) : null
                                            }
                                            label={order.state}
                                          />
                                        )}
                                      </div>
                                    </div>

                                    <div className="order-cell">
                                      <Tooltip title="Ver información">
                                        <Button
                                          style={{ backgroundColor: 'transparent', borderRadius: '5px' }}
                                          onClick={(event) => handleOpen(event, order)}

                                        >
                                          <InfoOutlinedIcon
                                            style={{ backgroundColor: 'transparent', border: 'none', color: '#056a6c' }}
                                          />
                                        </Button>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="order-cell order-cell-observacion" style={{ fontSize: '14px', width: '220px' }}>
                                    {order.state === "Aceptado sin Stock" ? (
                                      (order.pendingProducts.length > 0 || order.pendingOtherProducts.length > 0) ? (
                                        // Cuando hay productos sin stock y productos pendientes
                                        <span>Sin stock, con productos pendientes</span>
                                      ) : (
                                        <span>Sin stock para completar</span>
                                      )
                                    ) : (order.pendingProducts.length > 0 || order.pendingOtherProducts.length > 0) ? (
                                      <span>Aceptado con productos pendientes</span>
                                    ) : order.comment ? (
                                      // Limitar a 40 caracteres si es necesario
                                      <span>{order.comment.length > 40 ? `${order.comment.slice(0, 40)}...` : order.comment}</span>
                                    ) : null}
                                  </div>

                                </div>
                                <div className="card-content-row ">

                                  <div className="order-action-container">
                                    <Tooltip title="Ver pedido">
                                      <Link to={`/orders/edit/${order._id}`} style={{ textDecoration: 'none' }}>
                                        <Button
                                          className="btn-see-order"
                                          onClick={() => selected(order)}
                                          style={{ marginRight: 0 }}
                                        >
                                          <NavigateNextRoundedIcon className="icon-btn-see-order" />
                                        </Button>
                                      </Link>
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </div>

                      );
                    })

                ) : (
                  <div className="text-order">
                    No se encontraron pedidos.{" "}
                    <b>
                      <button onClick={handleClearFilteredData} className="btn-text-order">
                        Limpiar filtro
                      </button>
                    </b>
                  </div>
                )}
              </div>

              <ExportOrderModal
                show={orderToPdfModal}
                onHide={() => setOrderToPdfModal(false)}
                labs={isAdmin === "logistic" ? labsAssigned : AllLabs}
              />
            </>

          )}
        </div>

        {/* Popover para mostrar detalles del pedido */}
        <Popover
          open={isOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className="popover-custom">
            {selectedOrder && (
              <>
                <Typography className="popover-title" style={{ display: 'flex', gap: '10px' }}>
                  Pedido número: {selectedOrder.codigoPedido}
                </Typography>
                <Typography className="text-popover" sx={{ mt: 2, fontFamily: 'Montserrat', fontSize: '14px' }}>
                  <strong>Creado:</strong> {new Date(selectedOrder.createdAt).toLocaleDateString("es-ar")}
                </Typography>
                <Typography className="text-popover" sx={{ mt: 2, fontFamily: 'Montserrat', fontSize: '14px' }}>
                  <strong>Estado:</strong> {selectedOrder.state}
                </Typography>
                
                {[
                  { label: 'Aceptado', date: selectedOrder.acceptedAt },
                  { label: 'Enviado', date: selectedOrder.sentAt },
                  { label: 'Recibido', date: selectedOrder.receivedAt },
                  { label: 'Rechazado', date: selectedOrder.rejectedAt }
                ].map((state) =>
                  state.date ? (
                    <Typography key={state.label} className="text-popover" sx={{ mt: 2, fontFamily: 'Montserrat', fontSize: '14px' }}>
                      <strong>{state.label}:</strong> {new Date(state.date).toLocaleDateString("es-ar")}
                    </Typography>
                    
                  ) : null
                  
                )}
                {/* Mostrar la observación completa */}
                {selectedOrder.comment && (
                  <Typography     className="text-popover"
                  sx={{
                    mt: 2,
                    fontFamily: 'Montserrat',
                    fontSize: '14px',
                    width: '100%', // Ocupa todo el ancho
                    wordBreak: 'break-word', // Ajusta el texto en el ancho del contenedor
                  }}>
                    <strong>Observación:</strong> {selectedOrder.comment}
                  </Typography>
                )}


              </>
            )}
          </Box>
        </Popover>

        <AbmButtons
          item={orderSelected}
          role={isAdmin}
          funcion={deleteOrder}
          abm={"pedidos"}
        />
      </div>
      <Pagination className="pagination-all-tag">
        <Pagination.Prev
          onClick={() => changePage(pageNumber > 0 ? pageNumber - 1 : 0)}
          disabled={pageNumber === 0}
        />
        {Array.from({ length: pageCountForButton }).map((_, index) => (
          <Pagination.Item
            key={index}
            active={index === pageNumber}
            onClick={() => changePage(index)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          onClick={() => changePage(pageNumber < pageCountForButton - 1 ? pageNumber + 1 : pageCountForButton - 1)}
          disabled={pageNumber === pageCountForButton - 1}
        />
      </Pagination>

    </div >);
}
