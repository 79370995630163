import axios from 'axios';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { Table, Badge, Button } from 'react-bootstrap';
import AbmButtons from '../../ABMbotones/abmButtons';
import AnimacionSuccessful from '../../AnimacionSuccessful/animacionSuccessful';
import ProductsModal from './Components/ProductsModal';
import SpinnerComponent from '../../SpinnerComponent/SpinnerComponent';
const BACK_APP_URI = process.env.REACT_APP_BACK_APP_URI;


export const AllLabs = () => {
    
    const [AllLabs, setAllLabs] = useState([]);
    const [labSelected, setLabSelected] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

        // Estados para AnimacionSuccessful
    const [errorBool, setErrorBool] = useState(false);
    const [avisomsg, setAvisomsg] = useState('');
    const [variable, setVariable] = useState(false);

    const [showProductsModal, setShowProductsModal] = useState(false)
    const [productsToShowInModal, setProductsToShowInModal] = useState([])
    const [isOtherProductsShowingInModal, setIsOtherProductsShowingInModal] = useState(false)

    const [isLoading, setIsLoading] = useState(true)

    const closeProductModal = () => {
        setShowProductsModal(false)
    }


    const select = (name) => {
        setLabSelected(name._id);
    };

    const deselect = () => {
        setLabSelected(null);
    };

    function refreshPage() {
        window.location.reload(false);
    }

    useEffect(() => {
        async function getLabs(){
            setIsLoading(true)
            const token = Cookies.get("token"); 
            await axios
                .get(`${BACK_APP_URI}/laboratory`, {
                    headers: {
                        Authorization: `${token}`,
                    },
                })
                .then((res) => {
                    const labs = res.data.laboratories.map(lab => {
                        return {
                            ...lab,
                            products: [ ...lab.products, ...lab.tags.filter(tag => tag.isProducts)],
                            otherProducts: [ ...lab.otherProducts, ...lab.tags.filter(tag => !tag.isProducts)]
                        }
                    })
                    setAllLabs(labs);
                    setIsAdmin(res.data.userdata);
                });   
            setIsLoading(false)
        }
        getLabs();
    }, []);

    // Borrar un laboratorio

    const deleteLab = (id) => {
        axios({
            method: "delete",
            url: `${BACK_APP_URI}/laboratory/delete/${labSelected}`,
        }).then((data) => {
            setVariable(true);
            setErrorBool(false);
            setAvisomsg('Laboratorio eliminado correctamente');

        })
            
            
    };

    return (
        <>
        <div className= "main-container">
            <div className="header--container">
                <h1 className='title--header'>Laboratorios </h1>
                </div>
                <div className="card--container">
                <SpinnerComponent isLoading={isLoading} isAlternative={!AllLabs.length} alternative={<div>No se encontraron laboratorios</div>}>
                    <div className="table-container table-responsive">
                        <Table className='table-striped table-bordered table-hover '>
                            <thead responsive onClick={deselect}>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Nº de Sede</th>
                                    <th>Dirección</th>
                                    <th>Productos Permitidos</th>
                                    <th>Productos (Varios) Permitidos</th>
                                </tr>
                            </thead>
                            <tbody>
                            {AllLabs.map((name,officeNumber,address,) => (
                                <tr 
                                    key={name._id}
                                    onClick={() => select(name)}
                                    className={
                                    name._id === labSelected ? "selected-row tr-table" : "tr-table"
                                    }
                                >
                                        <td>{name.name}</td>
                                        <td>{name.officeNumber}</td>
                                        <td>{name.address}</td>
                                        <td>
                                            
                                            {name.products.length > 0 ? (
                                                <Button className="btn-list-table" onClick={() => {
                                                    setShowProductsModal(true)
                                                    setProductsToShowInModal(name.products)
                                                    setIsOtherProductsShowingInModal(false)
                                                }}>Ver   Lista</Button>
                                            ) : (
                                                <p>Sin productos asignados</p>
                                            )}
                                        </td>
                                        <td>
                                            {name.otherProducts.length > 0 ? (
                                                <Button className="btn-list-table" onClick={() => {
                                                    setShowProductsModal(true)
                                                    setProductsToShowInModal(name.otherProducts)
                                                    setIsOtherProductsShowingInModal(true)
                                                }}>Mostrar Lista</Button>
                                            ) : (
                                                <p>Sin productos asignados</p>
                                            )}
                                        </td>
                                </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </SpinnerComponent>
                </div>
                {/* Popup */}
            {variable ? (
            <AnimacionSuccessful 
            errorBool={errorBool} 
            avisomsg={avisomsg}
            refreshPage={refreshPage}
            /> 

            ) : (
            <></>
            )}
                <AbmButtons
                    item={labSelected}
                    abm="laboratory"
                    funcion = {deleteLab}
                    role = {isAdmin}
                />{" "}
            
            </div>
            <ProductsModal show={showProductsModal} products={productsToShowInModal} closeProductModal={closeProductModal} isOtherProductsShowingInModal={isOtherProductsShowingInModal}/> 
        </>
       
    );
};
