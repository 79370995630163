import React, { useEffect, useState } from "react"
import { Button, Modal, Table } from "react-bootstrap"
import editIcon from "./edit.png"
import AddIcon from "./../../../../Assets/Img/iconAddBlack.svg"
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined"
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined"
import LabelIcon from "@mui/icons-material/Label"
import { Tooltip } from "@mui/material"

export default function ItemRow({ item, setItemToEdit, setUsoSmnalToEdit, setShowModal, isOtherProducts, isNotEditable, backgroundColorClass }) {
	const [showLots, setShowLots] = useState(false)
	const [showProductsFromTag, setShowProductsFromTag] = useState(false)

	const [hasStock, setHasStock] = useState(true)
	const [isLotsUpToDate, setIsLotsUpToDate] = useState(true)

	useEffect(() => {
		setHasStock(() => (item.quantity < item.usoSmnal ? false : true))
		if (isOtherProducts) return
		setIsLotsUpToDate(() => !item?.lots?.some(lot => new Date(lot.expireDate) < new Date()))
	})

	const formatDisplayName = name => {
		return name
			.replace(/([a-z])([A-Z0-9])/g, "$1 $2") // Agregar espacio antes de las mayúsculas o números
			.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Separar secuencias de mayúsculas
			.replace(/(\d+)/g, " $1") // Agregar espacio antes de los números
	}

	const Td = ({ children }) => <td className={backgroundColorClass}>{children}</td>

	return (
		<>
			<tr
				key={item._id}
				className={`tr-table ${backgroundColorClass}`}
			>
				<Td>
					{item.hasOwnProperty("isProducts") ? (
						<>
							<span style={{
								marginRight: ".5rem"
							}}>{formatDisplayName(item.name)}</span>
							<Tooltip title="Etiqueta">
								<LabelIcon fontSize="small" />
							</Tooltip>
						</>
					) : (
						formatDisplayName(item.name)
					)}
				</Td>

				<Td>{item.provider.name} </Td>
				<Td>{`${item.quantity} (${isNotEditable ? "ud." : item?.unitType?.name || item?.measure?.name})`}</Td>
				<Td>{!isNotEditable ? `${item.usoSmnal} (${isNotEditable ? "ud." : item?.unitType?.name || item?.measure?.name})` : <div>-</div>}</Td>
				<Td className="quantity-th">
					{!isNotEditable && hasStock && isLotsUpToDate && <div className="correcto">EN STOCK</div>}
					{!isNotEditable && !hasStock && <div className="error mb-1">SIN STOCK</div>}
					{!isLotsUpToDate && <div className="error">LOTES VENCIDOS</div>}
					{isNotEditable && isLotsUpToDate && <div>-</div>}
				</Td>
				<Td>
					{!isNotEditable ? (
						<Button
							variant="outline-light"
							children="Editar"
							onClick={() => {
								setShowModal(true)
								setItemToEdit(item)
								setUsoSmnalToEdit(item.usoSmnal)
							}}
						>
							<img
								src={editIcon}
								alt="Editar"
							/>
						</Button>
					) : (
						<div>-</div>
					)}
				</Td>
				<Td>
					{item.lots || (item.hasOwnProperty("isProducts") && item.products?.length) || item.otherProducts?.length ? (
						<button
							className="lotButton"
							onClick={() => {
								if (item.lots) {
									setShowLots(prev => !prev)
								} else {
									setShowProductsFromTag(prev => !prev)
								}
							}}
						>
							{showLots || showProductsFromTag ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
						</button>
					) : (
						<div></div>
					)}
				</Td>
			</tr>

			{showLots && (
				<tr className={backgroundColorClass}>
					<td
						colSpan={8}
						style={{
							padding: 0,
						}}
					>
						<Table
							size="sm"
							style={{
								margin: 0,
							}}
							bordered
						>
							<tbody>
								<tr>
									<Td>Numero de Lote</Td>
									<Td>Fecha de Ingreso</Td>
									<Td>Fecha de Vencimiento</Td>
									<Td>Cantidad</Td>
									<Td>Cantidad de lotes</Td>
								</tr>
								{item?.lots.map(lot => {
									return (
										<tr key={lot._id}>
											<Td>{lot.lotNumber}</Td>
											<Td>{`${new Date(lot.acquireDate).getDate()}/${new Date(lot.acquireDate).getMonth() + 1}/${new Date(lot.acquireDate).getFullYear()}`}</Td>
											<Td className={new Date(lot.expireDate) < new Date() ? "color-red" : ""}>{`${new Date(lot.expireDate).getDate()}/${
												new Date(lot.expireDate).getMonth() + 1
											}/${new Date(lot.expireDate).getFullYear()}`}</Td>
											<Td>{lot.quantity}</Td>
											<Td>{lot.lotQuantity}</Td>
										</tr>
									)
								})}
							</tbody>
						</Table>
					</td>
				</tr>
			)}

			{showProductsFromTag &&
				(item.products.length
					? item.products.map(product => {
							return (
								<ItemRow
									key={product._id}
									backgroundColorClass={backgroundColorClass}
									isOtherProducts={false}
									item={product}
									isNotEditable
								/>
							)
					  })
					: item.otherProducts.map(product => {
							return (
								<ItemRow
									key={product._id}
									backgroundColorClass={backgroundColorClass}
									isOtherProducts={true}
									item={product}
									isNotEditable
								/>
							)
					  }))}
		</>
	)
}
