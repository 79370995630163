import React, { useState } from "react"
import iconAdd from "./../../../../Assets/Img/iconAdd2Edit.svg"
import { Fragment } from "react"
import { Form, Table } from "react-bootstrap"
import LotRow from "./LotRow"
import LabelIcon from "@mui/icons-material/Label"
import { Tooltip } from "@mui/material"
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined'
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined'

export default function ItemRow({ item, select, deselect, itemSelected, isTag, isBioSection, isEven, allCategories, reload }) {
	const [isOpen, setIsOpen] = useState(false)
	const handleSelect = () => {
		if (item._id === itemSelected) {
			deselect()
		} else {
			select(item)
		}
	}

	const handleOpenAndClose = () => {
		setIsOpen(prev => !prev)
	}

	const backgroundColor = isEven ? "backgound-veryLight" : ""

	const Td = ({ children }) => {
		return <td 
			className={`${backgroundColor}`}
			style={{
				padding: "8px"
			}}
		>{children}</td>
	}
	const formatDisplayName = name => {
		return name
			.replace(/([a-z])([A-Z0-9])/g, "$1 $2") // Agregar espacio antes de las mayúsculas o números
			.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2") // Separar secuencias de mayúsculas
			.replace(/(\d+)/g, " $1") // Agregar espacio antes de los números
	}

	return (
		<Fragment key={item._id}>
			<tr
				className={` ${backgroundColor} text-color-light`}
			>
				<Td>
					{!isTag && (
						<Form.Check
							value={item._id}
							type="checkbox"
							checked={itemSelected === item._id}
							onChange={handleSelect}
						/>
					)}
				</Td>
				<Td>{item.code || "-"}</Td>
				<Td>
					<div className="flex gap-2">
						{isTag ? (
							<>
								<b>{formatDisplayName(item.name)}</b>
								<Tooltip title="Etiqueta">
									<LabelIcon fontSize="small" />
								</Tooltip>
							</>

						) : formatDisplayName(item.name)}
					</div>
				</Td>

				<Td>{item.brand || "-"}</Td>

				{isBioSection && (
					<>
						<Td>{item.ubn || "-"}</Td>
					</>
				)}
				{!isBioSection && <Td>{item.category?.name || allCategories?.find(category => category._id === item.category)?.name}</Td>}
				<Td>{item.quantity}</Td>
				<Td>{item.weightOrVolume || "-"}</Td>
				<Td>{item?.unitType?.name || item?.measure?.name}</Td>
				<Td>
					{item.provider && item.provider.name} {item.provider && item.provider.lastName}
				</Td>
				<Td>
					{((isTag && item.products?.length || item.otherProducts?.length) || isBioSection) && (
						<button
							className="lotButton"
							onClick={handleOpenAndClose}
						>
							{isOpen ? <ExpandLessOutlinedIcon/> : <ExpandMoreOutlinedIcon/>}
						</button>
					)}
				</Td>
			</tr>

			{isTag &&
				isOpen &&
				(item.products.length
					? item.products.map(product => {
							return (
								<ItemRow
									key={product._id}
									item={product}
									deselect={deselect}
									select={select}
									itemSelected={itemSelected}
									isBioSection={isBioSection}
									isEven={isEven}
									reload={reload}
								/>
							)
					  })
					: item.otherProducts.map(product => {
							return (
								<ItemRow
									key={product._id}
									item={product}
									deselect={deselect}
									select={select}
									itemSelected={itemSelected}
									isBioSection={isBioSection}
									isEven={isEven}
									reload={reload}
								/>
							)
					  }))}

			{!isTag && isBioSection && isOpen && (
				<tr >
					<td
						colSpan={10}
						style={{
							padding: 0,
						}}
					>
						<Table
							size="sm"
							style={{
								margin: 0,
							}}
							bordered
						>
							<tbody>
								<tr>
									<Td>Numero de Lote</Td>
									<Td>Fecha de Ingreso</Td>
									<Td>Fecha de Vencimiento</Td>
									<Td>Cantidad</Td>
									<Td>Cantidad de lotes</Td>
									<Td>Editar</Td>
								</tr>
								{item?.lots.map(lot => {
									return (
										<LotRow
											key={lot._id}
											lot={lot}
											isEven={isEven}
											reload={reload}
										/>
									)
								})}
							</tbody>
						</Table>
					</td>
				</tr>
			)}
		</Fragment>
	)
}
